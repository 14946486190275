import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/ian/source/repos/oconnorit/src/components/projectTemplate.tsx";
import NE1 from "../../assets/images/fulls/NuclearEngine.png";
import NE2 from "../../assets/images/fulls/NuclearEngine2.png";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Voxel game set in space`}</p>
    <p>{`This project uses:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://premake.github.io/"
        }}>{`premake`}</a>{` for generating the project`}</li>
      <li parentName="ul">{`OpenGL`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.libsdl.org/"
        }}>{`SDL (Simple DirectMedia Layer)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://lodev.org/lodepng/"
        }}>{`LodePNG`}</a>{` for handling images`}</li>
    </ul>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`Voxel Environment`}</li>
      <li parentName="ul">{`Fast Asset Loading`}</li>
    </ul>
    <img className="image fit" src={NE1} alt="Game Scene - Lake Background" />
    <img className="image fit" src={NE2} alt="Game Scene - Red Space" />
    <h4>{`License`}</h4>
    <p>{`All rights reserved`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      